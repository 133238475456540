import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  CircularProgress,
  Link,
  Pagination,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import { useParams, useLocation } from "react-router-dom";
import { getApi } from "../../services";
import Cookies from "js-cookie";
import moment from "moment";
import axios from "axios";
import {
  PaymentState as SearchReduxState,
  useSelector,
  themeReducer,
  ApiState,
} from "../../redux";
import { useDispatch } from "react-redux";
import { Resetdata, UpdatePaymentData } from "../../redux/User/actions";
import { ThemeProvider, useTheme } from "@mui/material/styles";
//import ThemeSwitcher from "../../components/ThemeSwitcher";
import { setThemeColor } from "../../redux/theme/action/themeActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
//import reduxStore from "../../redux/store";
import { MobileState as MReduxState } from "../../redux";
import { UpdateApiData, AResetData } from "./../../redux/Api/actions/index";

type DocDetails = {
  [key: string]: any;
};
type Doctor = {
  [key: string]: any;
};
export const PatientsIndex: React.FC = () => {
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const themeRedux = useSelector<themeReducer>((state) => state.themeReducer);
  const apistate = useSelector<ApiState>((state) => state.ApiReducer);
  const searchRedux2 = useSelector<MReduxState>((state) => state.mobileReducer);

  const { id } = useParams(); // Access the "id" parameter from the URL
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const shouldLog = useRef(true);
  const getapi = getApi();
  const [data, setData] = useState<any>([]);
  const [details, setDetails] = useState<DocDetails>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  //const [reasondata, setReasonData] = useState<any>([]);
  const [Patient_Alert, setAlert] = useState();
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  let locationid = location.search.split("?");
  const [location_id, setlocationid] = useState(
    locationid[1] ? atob(locationid[1]) : ""
  );

  useEffect(() => {
    dispatch(UpdatePaymentData("location_id", location_id));
    dispatch(UpdateApiData("location_id", location_id));
  }, [location_id]);

  useEffect(() => {
    // dispatch(UpdatePaymentData('login_from', location.search));
    dispatch(UpdateApiData("login_from", location.search));

    let ll = location_id.split("_");

    if (ll[0] === "S25880ANA") {
      dispatch(setThemeColor("pink"));
    } else {
      dispatch(setThemeColor("blue"));
    }
  }, [location_id]);

  useEffect(() => {
    // console.log('Used Redux',searchRedux2  )
  }, [searchRedux2]);

  useEffect(() => {
    dispatch(Resetdata());
    dispatch(AResetData());
  }, []);
  useEffect(() => {
    const CurURL = window.location.href;
    var separatedArray = CurURL.split("id=");
  }, [details]);

  const [listorder, setlistorder] = useState("");
  const [serverdown, setserverdown] = useState("");

  const [length, setlength] = useState(1);
  const [status, setStatus] = useState<number>();

  /*  Check the URL Function */
  useEffect(() => {
    let locationid = location.search.split("?");

    //console.log('Location test',locationid[1])
    if (locationid[1]) {
      // console.log('Location url right')
      if (shouldLog.current) {
        shouldLog.current = false;
        getData2(); // function
      }
    } else {
      setlength(0);
      setStatus(404);
    }
  }, []);

  /* practicedetails get function */

  const getData2 = async () => {
    setLoading(true);
    dispatch(UpdatePaymentData("Location_Name", ""));
    try {
      let data = {
        location_id: location_id,
      };

      const res = await getapi.post("practicedetails/", data);
      setlength(res.data.data.length);
      setStatus(res.data.Status);

      setserverdown(res.data.data[0].Is_ServerDown_Msg);
      setAlert(res.data.data[0].Patient_Alert);
      dispatch(
        UpdatePaymentData(
          "Is_ServerDown_Msg",
          res.data.data[0].Is_ServerDown_Msg
        )
      );

      dispatch(
        UpdatePaymentData("Location_Name", res.data.data[0].Location_Name)
      );
      Cookies.set("Location_Name", res.data.data[0].Location_Name);
      Cookies.set("Address", res.data.data[0].Address1);
      Cookies.set("Phone_no", res.data.data[0].Phone_Number);
      // Cookies.set('BTN_1_URL', res.data.data[0].BTN_1_URL)
      // Cookies.set('BTN_1', res.data.data[0].BTN_1)
      Cookies.set(
        "Doctors_ListingOrder",
        res.data.data[0].Doctors_ListingOrder
      );

      dispatch(
        UpdateApiData(
          "Doctors_ListingOrder",
          res.data.data[0].Doctors_ListingOrder
        )
      );

      setlistorder(res.data.data[0].Doctors_ListingOrder);
      setDetails(res.data.data[0]);
      if (res.data.Success) {
        setLoading(false);
        getData();
      }

      let paramlocationid = location.search.split("?");

      axios
        .get(
          "https://coddgpphp.coddapps.com.au/config/onlineapt/API.php?GET_TYPE=online&PARAM=" +
            paramlocationid
        )
        .then((response) => {
          ////console.log(response.data);
        })
        .catch((err) => {
          // //console.log("error", err);
        });
    } catch (err) {
      // Handle the error
      console.log("error", err);
      setLoading(false);
    }
  };

  // const storeDoctorData = (doctor: Doctor) => {
  //   const jsonString = JSON.stringify(doctor);
  //   Cookies.set("selecteddate", doctor.APTDate)
  //   // Set the expiration date to one day from the current date
  //   let expirationDate = new Date();
  //   expirationDate.setDate(expirationDate.getDate() + 1);
  //  // Set the cookie with the JSON string
  //   document.cookie = `DoctorDetails=${encodeURIComponent(jsonString)}; expires=0; path=/`;

  // };

  const storeDoctorData = (doctor: Doctor) => {
    // Create a new object without the unwanted fields

    const {
      Remarks,
      NameType,
      AHPRA,
      UniqueName,
      VCF,
      ProfPic,
      BillingDetails,
      ProfileNote,
      ...filteredDoctor
    } = doctor;
    // Convert the filtered doctor object to JSON string
    const jsonString = JSON.stringify(filteredDoctor);

    // Set the expiration date to one day from the current date
    let expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);

    // Set the cookie with the JSON string
    document.cookie = `DoctorDetails=${encodeURIComponent(
      jsonString
    )}; expires=0; path=/`;
  };

  useEffect(() => {
    const clearCookiesOnTabClose = () => {
      // Clear the cookies here
      Cookies.remove("selecteddate");
      Cookies.remove("Location_Name");
      // Cookies.remove('BTN_1_URL')
      // Cookies.remove('BTN_1')
      Cookies.remove("Doctors_ListingOrder");
      Cookies.remove("selectedSlotCount");
      Cookies.remove("userid");

      document.cookie =
        "DoctorDetails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "APTReason=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      dispatch(Resetdata());
      dispatch(AResetData());
    };

    window.addEventListener("beforeunload", clearCookiesOnTabClose);
    window.addEventListener("unload", clearCookiesOnTabClose);

    return () => {
      window.removeEventListener("beforeunload", clearCookiesOnTabClose);
      window.removeEventListener("unload", clearCookiesOnTabClose);
    };
  }, [location]);

  useEffect(() => {
    const clearCookiesOnTabClose = () => {
      // Clear the cookies here
      Cookies.remove("selecteddate");
      Cookies.remove("Location_Name");
      // Cookies.remove('BTN_1_URL')
      // Cookies.remove('BTN_1')
      Cookies.remove("Doctors_ListingOrder");
      Cookies.remove("selectedSlotCount");
      Cookies.remove("userid");

      document.cookie =
        "DoctorDetails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "APTReason=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      dispatch(Resetdata());
      dispatch(AResetData());
    };

    window.addEventListener("beforeunload", clearCookiesOnTabClose);
    window.addEventListener("unload", clearCookiesOnTabClose);

    return () => {
      window.removeEventListener("beforeunload", clearCookiesOnTabClose);
      window.removeEventListener("unload", clearCookiesOnTabClose);
    };
  }, []);

  useEffect(() => {
    if (serverdown) {
      navigate("/serverdown");
    }
  }, [serverdown]);

  /* Doctor List API function */

  const getData = () => {
    setLoading(true);

    let data = {
      location_id: location_id,
      doctorlist_order: Cookies.get("Doctors_ListingOrder")
        ? Cookies.get("Doctors_ListingOrder")
        : "1",
    };
    getapi
      .post("doctorlist/", data)
      .then((res) => {
        setLoading(false);
        if (res.data.Status != 200) {
          setlength(0);
          setStatus(404);
        }

        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const profileopenupdate = async (userid: any) => {
    try {
      let data = {
        user_id: userid,
        location_id: apistate.location_id,
        profileopen: "1",
      };

      const res = await getapi.post("profileopenupdate/", data);
    } catch (err) {}
  };

  const convertSecondsToHMS = (seconds: any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Convert to 12-hour format

    let hours12 = hours % 12 || 12;
    hours12 = hours12 === 0 ? 12 : hours12; // Handle midnight (12 AM)

    const amPm = hours < 12 ? "AM" : "PM";

    return `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm.toString().padStart(2, "0")}`;
  };

  return (
    <Fragment>
      {searchRedux2.LoginFrom === "MobileApp" && (
        <Breadcrumbs separator="<" aria-label="breadcrumb">
          <Link
            sx={{ m: 1 }}
            className="breadcrumb"
            underline="hover"
            key="1"
            color="inherit"
            onClick={() => navigate("/patientslist")}
          >
            <ChevronLeftIcon sx={{ mr: 0.5 }} />
            Back
          </Link>
        </Breadcrumbs>
      )}

      <div className={`App box-design ${themeRedux.themeColor}`}>
        {loading ? (
          <div className="d-flex align-items-center loader justify-content-center">
            <CircularProgress />
          </div>
        ) : (
          <Fragment>
            {/* <div className={`App ${themeRedux.themeColor}`} >
        <ThemeSwitcher />       
      </div> */}

            {!matches ? (
              <div className="card bg-light list-group desktop_view">
                {/* {!details.length ? */}
                {length === 0 || status !== 200 ? (
                  <Fragment>
                    <div
                      style={{
                        height: 250,
                      }}
                      className="d-flex align-items-center loader justify-content-center"
                    >
                      <Image
                        rounded
                        width={216}
                        height={194}
                        className="d-inline-flex align-left username object-fit-cover align-middle"
                        src={require("../../assets/images/Datanotfound.png")}
                        //  src={require('../../assets/images/user_1.png')}
                      />
                    </div>
                    <Typography className="d-flex  align-items-center loader justify-content-center InvalidRequest">
                      Invalid Request
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment>
                    {data.map((item: any, idx: number) => (
                      <>
                        {item.ShowAPTCanBook == "0" && (
                          <>
                            {/* {moment(item.APTDate).format("ddd DD MMM") !==
                      "Invalid date" && (  */}
                            <a className="list-group-item list-group-item-action">
                              <Row className="pd-10">
                                <Col
                                  md={1}
                                  className="d-flex pd-10 justify-content-center"
                                >
                                  <Image
                                    rounded
                                    width={65}
                                    height={65}
                                    className="d-inline-flex align-left username object-fit-cover align-middle"
                                    src={
                                      item.ProfPhotoN
                                        ? item.ProfPhotoN
                                        : require("../../assets/images/user_1.png")
                                    }
                                    //  src={require('../../assets/images/Dr.Dev.png')}
                                    roundedCircle
                                  />
                                </Col>

                                {moment(item.APTDate).format("ddd DD MMM") !==
                                "Invalid date" ? (
                                  <Col md={6} className="align-middle">
                                    <h5 className="mt-2 align-middle username">
                                      {" "}
                                      {item.ISDOCTOR} {item.UserName}
                                    </h5>
                                    <small className="text-muted align-middle sub_text">
                                      {/* {item.Gender} GP */}
                                      {item.Designation}
                                    </small>
                                  </Col>
                                ) : (
                                  <Col md={7} className="align-middle">
                                    <h5 className="mt-2 align-middle username">
                                      {" "}
                                      {item.ISDOCTOR} {item.UserName}
                                    </h5>
                                    <small className="text-muted align-middle sub_text">
                                      {/* {item.Gender} GP */}
                                      {item.Designation}
                                    </small>

                                    {moment(item.APTDate).format(
                                      "ddd DD MMM"
                                    ) === "Invalid date" && (
                                      <p className="align-middle next-available-text mb-3 mt-2">
                                        <b>
                                          {" "}
                                          No appointments available. Please call
                                          the practice to book Ph:{" "}
                                          {details.Phone_Number}{" "}
                                        </b>
                                      </p>
                                    )}
                                  </Col>
                                )}
                                {moment(item.APTDate).format("ddd DD MMM") !==
                                  "Invalid date" && (
                                  <Col className="float-end1 justify-content-end mt-2 mb-2">
                                    {moment(item.APTDate).format(
                                      "ddd DD MMM"
                                    ) === "Invalid date" && (
                                      <p className="mt-3 mb-5">
                                        <b className="text-center align-center text-muted sub_text"></b>
                                      </p>
                                    )}
                                    {moment(item.APTDate).format(
                                      "ddd DD MMM"
                                    ) !== "Invalid date" && (
                                      <p className="align-middle next-available-text mb-3">
                                        <b>
                                          Next Available:{" "}
                                          <span className="mobile-block">
                                            {moment(item.APTDate).format(
                                              "ddd DD MMM"
                                            )}{" "}
                                            {convertSecondsToHMS(item.APTTime)}
                                          </span>
                                        </b>
                                      </p>
                                    )}

                                    {moment(item.APTDate).format(
                                      "ddd DD MMM"
                                    ) !== "Invalid date" && (
                                      <div className="d-flex1">
                                        <Row>
                                          {item.ProfileLink && (
                                            <Col md={6}>
                                              <Button
                                                className="d_button b-width-full"
                                                variant="outline-secondary"
                                                //  size="sm"
                                                onClick={() => {
                                                  window.location.href =
                                                    item.ProfileLink;
                                                }}
                                              >
                                                View Profile
                                              </Button>
                                            </Col>
                                          )}
                                          <Col md={6} className="mx-auto">
                                            <Button
                                              className="d_button b-width-full"
                                              id={item.UserId}
                                              onClick={() => {
                                                storeDoctorData(item);
                                                dispatch(
                                                  UpdateApiData(
                                                    "userid",
                                                    item.UserId
                                                  )
                                                );
                                                Cookies.set(
                                                  "userid",
                                                  item.UserId
                                                );
                                                dispatch(
                                                  UpdateApiData(
                                                    "selecteddate",
                                                    item.APTDate
                                                  )
                                                );
                                                Cookies.set(
                                                  "selecteddate",
                                                  item.APTDate
                                                );
                                                profileopenupdate(item.UserId);
                                                if (Patient_Alert === 1) {
                                                  navigate("/patient-alert/");
                                                } else {
                                                  navigate("/profile");
                                                }
                                              }}
                                            >
                                              Book Appointment
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    )}
                                  </Col>
                                )}
                              </Row>
                              <Col className="width-96"></Col>
                            </a>
                            {/* )} */}
                          </>
                        )}

                        {item.ShowAPTCanBook == "1" && (
                          <>
                            {/* {moment(item.APTDate).format("ddd DD MMM") !== 
                      "Invalid date" && (*/}
                            <a className="list-group-item list-group-item-action">
                              <Row className="pd-10">
                                <Col
                                  md={1}
                                  className="d-flex pd-10 justify-content-center"
                                >
                                  <Image
                                    rounded
                                    width={65}
                                    height={65}
                                    className="d-inline-flex align-left username object-fit-cover align-middle"
                                    src={
                                      item.ProfPhotoN
                                        ? item.ProfPhotoN
                                        : require("../../assets/images/user_1.png")
                                    }
                                    //  src={require('../../assets/images/user_1.png')}
                                    roundedCircle
                                  />
                                </Col>
                                <Col md={11} className="align-middle">
                                  <h5 className="mt-2 align-middle username">
                                    {" "}
                                    {item.ISDOCTOR} {item.UserName}
                                  </h5>
                                  <small className="text-muted align-middle sub_text">
                                    {/* {item.Gender} GP */}
                                    {item.Designation}
                                  </small>
                                  <h5 className="align-middle shownotbookmsg">
                                    {" "}
                                    {item.ISDOCTOR} {item.UserName} does not
                                    accept online appointments, Please call the
                                    reception to book your appointment Ph{" "}
                                    {details.Phone_Number}{" "}
                                  </h5>
                                </Col>
                              </Row>
                              <Col className="width-96"></Col>
                            </a>
                            {/* )} */}
                          </>
                        )}
                      </>
                    ))}
                  </Fragment>
                )}
              </div>
            ) : (
              <div className="homepage_mobile">
                <div className="card bg-light list-group">
                  {length === 0 || status !== 200 ? (
                    <Fragment>
                      <div
                        style={{
                          height: 250,
                        }}
                        className="d-flex align-items-center loader justify-content-center"
                      >
                        <Image
                          rounded
                          width={216}
                          height={194}
                          className="d-inline-flex align-left username object-fit-cover align-middle"
                          src={require("../../assets/images/Datanotfound.png")}
                          //  src={require('../../assets/images/user_1.png')}
                        />
                      </div>
                      <Typography className="d-flex  align-items-center loader justify-content-center InvalidRequest">
                        Invalid Request
                      </Typography>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {data.map((item: any, idx: number) => (
                        <>
                          {item.ShowAPTCanBook == "0" && (
                            <>
                              {/* {moment(item.APTDate).format("ddd DD MMM") !==
                        "Invalid date" && ( */}
                              <a className="list-group-item list-group-item-action">
                                <Row>
                                  <Col
                                    xs={3}
                                    className="text-center d-flex justify-content-center mt-2"
                                  >
                                    <Image
                                      rounded
                                      width={60}
                                      height={60}
                                      className="d-inline-flex align-left username object-fit-cover align-middle"
                                      // src={item.image}
                                      src={
                                        item.ProfPhotoN
                                          ? item.ProfPhotoN
                                          : require("../../assets/images/user_1.png")
                                      }
                                      //  src={require('../../assets/images/user_1.png')}
                                      roundedCircle
                                    />
                                  </Col>
                                  <Col xs={9}>
                                    <h5 className="mt-2 align-middle username">
                                      {" "}
                                      {item.ISDOCTOR} {item.UserName}{" "}
                                    </h5>
                                    <small className="text-muted align-middle sub_text">
                                      {/* {item.Gender} GP */}
                                      {item.Designation}
                                    </small>

                                    {moment(item.APTDate).format(
                                      "ddd DD MMM"
                                    ) === "Invalid date" && (
                                      <p className="align-middle next-available-text mt-2 mb-3 cl-gray">
                                        <b>
                                          No appointments available. Please call
                                          the practice to book Ph{" "}
                                          {details.Phone_Number}
                                        </b>
                                      </p>
                                    )}

                                    {moment(item.APTDate).format(
                                      "ddd DD MMM"
                                    ) !== "Invalid date" && (
                                      <p className="align-middle next-available-text mt-2 mb-3">
                                        <Row>
                                          <div className="col-md-12 col-12">
                                            <b>
                                              Next Available:{" "}
                                              <span className="mobile-block">
                                                {" "}
                                                {moment(item.APTDate).format(
                                                  "ddd DD MMM"
                                                )}{" "}
                                                {convertSecondsToHMS(
                                                  item.APTTime
                                                )}
                                              </span>
                                            </b>
                                          </div>
                                        </Row>
                                      </p>
                                    )}
                                    {moment(item.APTDate).format(
                                      "ddd DD MMM"
                                    ) !== "Invalid date" && (
                                      <div className="mbtn">
                                        <Row>
                                          {item.ProfileLink && (
                                            <Col xs={5} className="pd-0-3">
                                              <Button
                                                style={{
                                                  marginRight: 10,
                                                }}
                                                className="d_button"
                                                variant="outline-secondary"
                                                //  size="sm"
                                                onClick={() => {
                                                  window.location.href =
                                                    item.ProfileLink;
                                                }}
                                              >
                                                View Profile
                                              </Button>
                                            </Col>
                                          )}
                                          <Col xs={7} className="pd-0 mx-auto">
                                            <Button
                                              className="d_button"
                                              onClick={() => {
                                                storeDoctorData(item);
                                                dispatch(
                                                  UpdateApiData(
                                                    "userid",
                                                    item.UserId
                                                  )
                                                );
                                                Cookies.set(
                                                  "userid",
                                                  item.UserId
                                                );
                                                dispatch(Resetdata());
                                                dispatch(
                                                  UpdateApiData(
                                                    "selecteddate",
                                                    item.APTDate
                                                  )
                                                );
                                                Cookies.set(
                                                  "selecteddate",
                                                  item.APTDate
                                                );
                                                profileopenupdate(item.UserId);
                                                if (Patient_Alert === 1) {
                                                  navigate("/patient-alert/");
                                                } else {
                                                  navigate("/profile");
                                                }
                                              }}
                                            >
                                              Book Appointment
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                                <Col className="width-96"></Col>
                              </a>
                              {/* )} */}
                            </>
                          )}

                          {item.ShowAPTCanBook == "1" && (
                            <>
                              {/* {moment(item.APTDate).format("ddd DD MMM") !==
                            "Invalid date" && ( */}
                              <a className="list-group-item list-group-item-action">
                                <Row>
                                  <Col
                                    xs={3}
                                    className="text-center d-flex justify-content-center mt-2"
                                  >
                                    <Image
                                      rounded
                                      width={60}
                                      height={60}
                                      className="d-inline-flex align-left username object-fit-cover align-middle"
                                      // src={item.image}
                                      src={
                                        item.ProfPhotoN
                                          ? item.ProfPhotoN
                                          : require("../../assets/images/user_1.png")
                                      }
                                      //  src={require('../../assets/images/user_1.png')}
                                      roundedCircle
                                    />
                                  </Col>
                                  <Col xs={9}>
                                    <h5 className="mt-2 align-middle username">
                                      {" "}
                                      {item.ISDOCTOR} {item.UserName}{" "}
                                    </h5>
                                    <small className="text-muted align-middle sub_text">
                                      {/* {item.Gender} GP */}
                                      {item.Designation}
                                    </small>

                                    <h5 className="align-middle shownotbookmsg">
                                      {" "}
                                      {item.ISDOCTOR} {item.UserName} does not
                                      accept online appointments, Please call
                                      the reception to book your appointment Ph{" "}
                                      {details.Phone_Number}
                                    </h5>
                                  </Col>
                                </Row>
                                <Col className="width-96"></Col>
                              </a>
                              {/* )} */}
                            </>
                          )}
                        </>
                      ))}
                    </Fragment>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
